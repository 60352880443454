<template>
  <v-snackbar :color="color" :timeout="timeout" v-model="isVisible">
    <strong>{{ title }}</strong> - {{ description }}
    <template v-slot:actions>
      <v-btn v-if="actionButton" @click="handleActionClick">
        {{ actionButton.text }}
      </v-btn>
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { ActionButton } from '@/utils/triggerUtils';

export default defineComponent({
  name: "Toast",
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "success"
    },
    timeout: {
      type: Number,
      default: 5000
    },
    actionButton: {
      type: Object as PropType<ActionButton | null>,
      default: null
    }
  },
  data() {
    return {
      isVisible: true
    };
  },
  methods: {
    handleActionClick() {
      if (this.actionButton && this.actionButton.action) {
        this.actionButton.action();
      }
      this.close();
    },
    close() {
      this.isVisible = false;
      this.$emit('close', this.id);
    }
  }
});
</script>