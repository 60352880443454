// src/router/index.ts
import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store/user";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/TemplateImproveList.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
  },
  {
    path: "/google-auth",
    name: "Google Auth",
    component: () => import("@/views/GoogleAuth.vue"),
  },
  {
    path: "/google-auth/callback",
    name: "Google Auth callback",
    component: () => import("@/views/GoogleAuthCallback.vue"),
  },
  {
    path: "/ticket/:id",
    name: "Templates Improvement",
    component: () => import("@/views/TemplateImproveTwo.vue"),
  },
  {
    path: "/auth",
    name: "LoginOrResigster",
    component: () => import("@/views/LoginOrResigster.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const isAuthenticated = !!userStore.getUserStored.api_key;
  const authNotRequired = [
    "LoginOrResigster",
    "Login",
    "SignUp",
    "Google Auth",
    "Google Auth callback",
  ];

  if (!isAuthenticated && !authNotRequired.includes(to.name as string)) {
    next({ name: "LoginOrResigster" });
  } else {
    next();
  }
});

export default router;
