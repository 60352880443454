import { useUserStore } from '@/store/user';
import router from '@/router';

export const getApiHeaders = () => {
  const userStore = useUserStore()
  const loggedUser = userStore.getUserStored;

  const apiKey = loggedUser.api_key;
  const apiUsername = loggedUser.organization_id;

  if (!apiKey || !apiUsername) {
    router.push('/login');
    throw new Error('API Key or Username not found');
  }else{
    console.log('API Key:', apiKey);
    console.log('API Username:', apiUsername);
  }

  const axiosConfig = {
    headers: {
      'X-API-KEY': apiKey,
      'X-API-USERNAME': apiUsername
    }
  };

  return axiosConfig;
}