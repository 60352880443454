// stores/user.ts

import { defineStore } from "pinia";
import { LoggedUser, EmptyLoggedUser } from "@/types/LoggedUser";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    ...EmptyLoggedUser,
  }),
  getters: {
    getUserStored(state): LoggedUser {
      return { ...state };
    },
  },
  actions: {
    setUser(user: LoggedUser) {
      Object.assign(this, user);
    },
    clearUser() {
      Object.assign(this, EmptyLoggedUser);
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "user_store",
        storage: localStorage,
        paths: [
          "api_key",
          "organization_id",
          "email",
          "s3_access_key",
          "s3_secret_access_key",
        ],
      },
    ],
  },
});
