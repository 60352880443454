export interface LoggedUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  organization_id: string;
  status: number;
  created_at: string;
  updated_at: string;
  api_key: string;
  s3_access_key?: string;
  s3_secret_access_key?: string;
}

export const EmptyLoggedUser: LoggedUser = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  organization_id: "",
  status: 0,
  created_at: "",
  updated_at: "",
  api_key: "",
  s3_access_key: "",
  s3_secret_access_key: "",
};
