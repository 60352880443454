import {
  VuetifyTiptap,
  VuetifyViewer,
  createVuetifyProTipTap,
} from "vuetify-pro-tiptap";
import {
  BaseKit,
  Bold,
  Italic,
  Underline,
  Heading,
  BulletList,
  OrderedList,
  Link,
  Image,
  Video,
  Table,
  History,
} from "vuetify-pro-tiptap";
import FileHandler from '@tiptap-pro/extension-file-handler'
import "vuetify-pro-tiptap/style.css";
import axios from "axios";
import { getPresignedUrl } from "./services/AttachmentsService";
import { ref } from "vue";

const attachments = ref<string[]>([]);
const isUploading = ref(false);


export const vuetifyProTipTap = createVuetifyProTipTap({
  lang: "en",
  components: {
    VuetifyTiptap,
    VuetifyViewer,
  },
  extensions: [
    BaseKit.configure({
      placeholder: {
        placeholder: "Enter some text...",
      },
    }),
    Bold,
    Italic,
    Underline,
    Heading,
    BulletList,
    OrderedList,
    Link,
    Image.configure({
      upload: async (files: File) => {
        const file = (files as unknown as File[])[0];
        const { url, file_name } = await getPresignedUrl({
          name: file.name,
          type: file.type,
        });

        const newFile = new File([file], file_name, {
          type: file.type,
        });

        await axios.put(url, newFile, {
          headers: {
            "Content-Type": file.type,
          },
        });
        return `${import.meta.env.VITE_AWS_HOST}${file_name}`;
      },
    }),
    Video,
    Table.configure({ divider: true }),
    History.configure({ divider: true }),
    FileHandler.configure({
      allowedMimeTypes: [
        'image/png', 
        'image/jpeg', 
        'image/gif', 
        'image/webp', 
        'image/bmp', 
        'image/tiff', 
        'application/pdf', 
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'application/vnd.ms-excel', 
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
        'text/plain', 
        'text/csv', 
        'audio/mpeg', 
        'audio/wav', 
        'audio/ogg', 
        'video/mp4', 
        'video/avi', 
        'video/x-matroska', 
        'video/webm'
      ],
      onDrop: async (currentEditor, files, pos) => {
        for (const file of files) {
          isUploading.value = true;
          const { url, file_name } = await getPresignedUrl({
            name: file.name,
            type: file.type,
          });

          const newFile = new File([file], file_name, {
            type: file.type,
          });

          await axios.put(url, newFile, {
            headers: {
              "Content-Type": file.type,
            },
          });

          const fullUrl = `${import.meta.env.VITE_AWS_HOST}${file_name}`;

          if (file.type === 'application/pdf') {
            attachments.value.push(fullUrl);
            console.log("PDF added to attachments:", fullUrl);
          } else {
            currentEditor.chain().insertContentAt(pos, {
              type: 'image',
              attrs: {
                src: fullUrl,
              },
            }).run();
          }
        }
        isUploading.value = false;
      },
      onPaste: async (currentEditor, files, pos) => {
        for (const file of files) {
          isUploading.value = true;
          const { url, file_name } = await getPresignedUrl({
            name: file.name,
            type: file.type,
          });

          const newFile = new File([file], file_name, {
            type: file.type,
          });

          await axios.put(url, newFile, {
            headers: {
              "Content-Type": file.type,
            },
          });

          const fullUrl = `${import.meta.env.VITE_AWS_HOST}${file_name}`;

          if (file.type === 'application/pdf') {
            attachments.value.push(fullUrl);
            console.log("PDF added to attachments:", fullUrl);
          } else {
            currentEditor.chain().insertContentAt(pos, {
              type: 'image',
              attrs: {
                src: fullUrl,
              },
            }).run();
          }
        }
        isUploading.value = false;
      },
    }),
  ],
});

export { attachments, isUploading };