import { getApiHeaders } from "@/utils/servicesUtils";
import axios from "axios";

type FileMetadata = {
  name: string;
  type: string;
};

export const getPresignedUrl = async (fileData: FileMetadata) => {
  const endpoint = `${
    import.meta.env.VITE_API_HOST
  }/api/v1/organization/attachments/presignedurl`;
  try {
    const response = await axios.post(endpoint, fileData, getApiHeaders());
    console.log("getPresignedUrl response: ", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
