import { ref, Ref } from 'vue';

export interface ActionButton {
  text: string;
  action: () => void;
}

export interface ToastItem {
  id: number;
  title: string;
  description: string;
  color: string;
  timeout: number;
  actionButton?: ActionButton;
}

export const toasts: Ref<ToastItem[]> = ref([]);

let nextId = 0;

export const createToast = (
  title: string, 
  description: string, 
  color = 'success', 
  timeout = 5000, 
  actionButton?: ActionButton
) => {
  const id = nextId++;
  const toast: ToastItem = { id, title, description, color, timeout, actionButton };
  toasts.value.push(toast);

  setTimeout(() => {
    const index = toasts.value.findIndex(t => t.id === id);
    if (index !== -1) {
      toasts.value.splice(index, 1);
    }
  }, timeout);
};

export const removeToast = (id: number) => {
  const index = toasts.value.findIndex(t => t.id === id);
  if (index !== -1) {
    toasts.value.splice(index, 1);
  }
};