/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from "@/plugins";
import router from "@/router";
import * as Sentry from "@sentry/vue";
import { vuetifyProTipTap } from "@/tiptap";

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

import VueDiff from "vue-diff";
import "vue-diff/dist/index.css";
import { useUserStore } from "./store/user";

console.log("NODE_ENV:", process.env.NODE_ENV);

const app = createApp(App);

registerPlugins(app);
app.use(VueDiff);
app.use(router);
app.use(vuetifyProTipTap);

if (process.env.NODE_ENV != "development") {
  console.log("Sentry enabled");
  Sentry.init({
    app,
    dsn: "https://75d4252c7c75fba6b0d990326230de79@o4506672034152448.ingest.sentry.io/4506699499241472",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Load user store to global object
const userStore = useUserStore();

console.log("Setting gurusupSettings", userStore.email);

(window as any).gurusupSettings = {
  userId: userStore.email,
  userName: userStore.email,
};

console.log("app.mount");
app.mount("#app");
