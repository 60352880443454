<template>
  <v-app>
    <v-app-bar app dark>
      <v-toolbar-title v-if="!isDemoEnviroment()">
        <router-link to="/">
          <img
            src="@/assets/logo.png"
            alt="Logo"
            style="height: 40px"
            class="mt-2"
          />
        </router-link>
      </v-toolbar-title>
      <v-toolbar-title v-else>
        <img
          src="@/assets/logo.png"
          alt="Logo"
          style="height: 40px"
          class="mt-2"
        />
      </v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-toolbar-items class="mr-5">
        <div v-if="enviroment == 'demo' && loggedUser.api_key">
          <v-btn class="ml-4" color="blue" dark @click="openContactSales">
            Contact Sales
          </v-btn>
          <v-chip class="mt-4 my-5" label text-color="white">
            Demo Account
          </v-chip>
        </div>
        <div v-else-if="enviroment != 'demo' && loggedUser.api_key">
          <v-chip class="mt-4 my-5" label text-color="white">
            {{ loggedUser.email }}
          </v-chip>
          <v-btn icon @click="router.push('/settings')">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn icon @click="logout">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </div>
      </v-toolbar-items>
    </v-app-bar>
    <br />
    <br />
    <br />
    <br />
    <router-view class="mx-5" />
    <div class="toast-container">
      <Toast
        v-for="toast in toasts"
        :key="toast.id"
        v-bind="toast"
        @close="removeToast"
      />
    </div>
  </v-app>
</template>

<script lang="ts" setup>
import Toast from "@/components/Toast.vue";
import { toasts, removeToast } from "@/utils/triggerUtils";

import { useUserStore } from "@/store/user";
import { onMounted, watch, reactive } from "vue";
import { useRouter } from "vue-router";
import { LoggedUser } from "@/types/LoggedUser";
import { isDemoEnviroment } from "./utils/enviromentUtils";

const userStore = useUserStore();
const loggedUser = reactive<LoggedUser>({ ...userStore.getUserStored });
const router = useRouter();
const enviroment = import.meta.env.VITE_ENVIROMENT;

onMounted(() => {
  if (!loggedUser.api_key) {
    console.log("No API KEY");
    router.push("/login-resigster");
  }
});

watch(
  () => userStore.getUserStored,
  (newUser) => {
    Object.assign(loggedUser, newUser);
  }
);

const openContactSales = () => {
  window.open("https://calendly.com/victor-guruwalk/20min", "_blank");
};

const logout = () => {
  loggedUser.email = "";
  loggedUser.api_key = "";
  userStore.clearUser();
  router.push("/login-resigster");
  console.log("Logged out");
};
</script>

<style>
.toast-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
</style>
